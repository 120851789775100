@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }

  *:focus {
    @apply outline-primary-400;
  }
}

@layer utilities {
  .striped div:nth-child(even) {
    @apply bg-gray-100;
  }
}
.cont {
  @apply max-w-full px-2 md:px-4;
}

.dnb-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.dnb-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.dnb-scroll::-webkit-scrollbar-thumb {
  @apply bg-emerald-green;
}

.border-spacing-0 {
  border-spacing: 0px 0px;
}

.sticky-row th {
  @apply top-0 bg-slate-100 z-10;
}

.sticky-total th {
  @apply top-0;
}

.dnb-table {
  border-collapse: collapse;
  width: 100%;
}
.dnb-table thead tr:not(:first-child) th {
  @apply shadow-sm top-0 bg-white;
}

.dnb-table tbody td,
.dnb-table thead th {
  @apply py-1 px-2;
}

.dnb-table-rowdetails tr td {
  @apply px-0 py-0 pb-1;
}

.prime-table th {
  @apply px-1 py-1.5;
}
.prime-table td {
  @apply px-1;
}

.arrow-up {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 14px solid green;
}
.arrow-down {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 14px solid #dc2a2a;
}

/* Tooltip text */
.sharp-tooltiptext {
  @apply bg-white invisible absolute opacity-0 p-1.5 text-black border border-emerald-green rounded-md shadow-md;
}

/* Show the tooltip text when you mouse over the tooltip container */
.sharp-tooltip:hover .sharp-tooltiptext {
  @apply visible opacity-100 z-10;
}

/* chart styles from flowbite */
.apexcharts-tooltip {
  @apply rounded-lg border-0 bg-white text-gray-500 shadow-lg dark:bg-gray-700 dark:text-gray-400 !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  @apply border-b border-gray-200 bg-gray-100 py-2 px-4 dark:border-gray-500 dark:bg-gray-600 !important;
}

.apexcharts-xaxistooltip {
  @apply rounded-lg border-0 bg-white text-gray-500 shadow-lg dark:bg-gray-700 dark:text-gray-300 !important;
}

.apexcharts-tooltip .apexcharts-tooltip-text-y-value {
  @apply dark:text-white;
}

.apexcharts-xaxistooltip-text {
  @apply text-sm font-medium !important;
}

.apexcharts-xaxistooltip:before,
.apexcharts-xaxistooltip:after {
  @apply border-0 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  @apply bg-green-500 !important;
}

/* DNB-Custom */

@keyframes slideInEffect {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInEffectMd {
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-in {
  animation-name: slideInEffect;
  animation-duration: 200ms;
  animation-timing-function: ease-out;
}
.slide-in-md {
  animation-name: slideInEffectMd;
  animation-duration: 200ms;
  animation-timing-function: ease-out;
}
